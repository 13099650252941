import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

/**
* Tags page (/tags)
*
* Displays all available tags
*
*/
const Tags = ({ data, location, pageContext }) => {
    // const tag = data.ghostTag
    const tags = data.allGhostTag.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout>
                <Container>
                    <Row>
                        <Col>
                            <header className="tag-header">
                                <h1 className="content-title">Banana Tasty Tags</h1>
                            </header>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <section className="post-feed">
                                {tags.map(({ node }) => (
                                    <div key={node.id} style={{ textAlign: `center` }}>
                                        <Link to={`/tag/` + node.slug} key={node.id}>
                                            <h2>{node.slug}</h2>
                                            <p>{node['description']}</p>
                                        </Link>
                                        <br />
                                        <hr />
                                    </div>
                                ))}
                    </section>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Pagination pageContext={pageContext} />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

Tags.propTypes = {
    data: PropTypes.shape({
        allGhostTag: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tags

export const pageQuery = graphql`
    query GhostTagsQuery($limit: Int, $skip: Int) {
        allGhostTag(
            sort: { order: DESC, fields: [name] },
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostTagFields
                }
            }
        }
    }
`